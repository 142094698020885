<template lang="pug">
  .account-settings
    AppNestedRoutesTabs(
      :routes="routes"
    )
</template>

<script>
  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    computed: {
      routes() {
        return [
          {
            name: "CarRental",
            title: this.$t("header.title.car_rental")
          },
          {
            name: "OtaAdmins",
            title: this.$t("header.title.ota_admin_list")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
